import { axios, API_ROUTES } from "@/config";

export default {
  async getAll(params) {
    let result = await axios.get(`${API_ROUTES.farmList.get}`, {
      params: params,
    });

    return result;
  },
  async find(slug, params) {
    let result = await axios.get(`${API_ROUTES.publishFarm.find}/${slug}`, {
      params: params,
    });

    return result;
  },
  async save(data) {
    let result = await axios.post(`${API_ROUTES.saleFarm.save}`, data);

    return result;
  },
  async findFarmPreview(slug, params) {
    let result = await axios.get(`${API_ROUTES.farmPreveiw.find}/${slug}`, {
      params: params,
    });

    return result;
  },
  async farmFavorite(params) {
    let result = await axios.get(`${API_ROUTES.favorite.get}`, {
      params: params,
    });
    return result;
  },

  async delete(params, id) {
    let result = await axios.get(`${API_ROUTES.favorite.save_delete}/${id}`, {
      params: params,
    });
    return result;
  },
};
